import {useState} from 'react';
import {Select, Stack, useToast} from '@halp/ui';
import {useI18n} from '../../i18n';
import {useMutation} from '@halp/api/graphql';
import type {LatestInteractionType} from '@halp/util/constants';
import {LATEST_INTERACTION_TYPE_NAMES} from '@halp/util/constants';
import {UpdateUserProfileDocument} from './UpdateUserProfile.mutation';
import styles from './LatestInteractionTypeDropdown.module.css';

interface Props {
	userId: string;
	latestInteractionType: LatestInteractionType | null;
}

type LatestInteractionTypeOption = {
	value: LatestInteractionType;
	label: string;
};

export function LatestInteractionTypeDropdown({
	userId,
	latestInteractionType,
}: Props) {
	const {t} = useI18n();
	const addToast = useToast();
	const [selectedValue, setSelectedValue] =
		useState<LatestInteractionType | null>(latestInteractionType);

	const [updateUserProfile] = useMutation(UpdateUserProfileDocument, {
		onSuccess: () => {
			addToast({type: 'success'});
		},
	});

	const handleChange = (interactionType: LatestInteractionType) => {
		if (!interactionType) return;

		setSelectedValue(interactionType);

		updateUserProfile({
			id: userId,
			user: {
				studentProfile: {
					latestInteractionType: interactionType,
				},
			},
		});
	};

	const LATEST_INTERACTION_TYPE_SELECT_OPTIONS = Object.keys(
		LATEST_INTERACTION_TYPE_NAMES,
	).map((key) => ({
		value: key as LatestInteractionType,
		label: t(LATEST_INTERACTION_TYPE_NAMES[key as LatestInteractionType]),
	}));

	return (
		<Stack direction="column" spacing="xs">
			<Select<LatestInteractionTypeOption>
				className={styles.fullWidth}
				value={
					selectedValue
						? LATEST_INTERACTION_TYPE_SELECT_OPTIONS.find(
								(option) => option.value === selectedValue,
							)
						: undefined
				}
				onChange={(option) => option && handleChange(option.value)}
				options={LATEST_INTERACTION_TYPE_SELECT_OPTIONS}
			/>
		</Stack>
	);
}
