import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import type {
	DomesticFunnelStage,
	FunnelStage,
	LatestInteractionType,
	QualificationStatus,
	CitizenshipTypes,
} from '@halp/util/constants';
import {friendlyURI, getURLQueryParameters, parseQueryString} from '@halp/util';
import {useQuery} from '@halp/api/graphql';
import {AdminCoachesIndexDocument} from '../../Coaches/AdminCoachesIndex.query';
import {useUserType} from '../../Users';
import {Paths} from '../../Paths';
import {QueryTable} from '../../DataTable';
import {StudentFilters} from '../StudentFilters';
import {StudentIndexDocument} from './StudentIndex.query';
import {
	useStudentsTableColumns,
	type StudentsTableColumnsType,
} from './StudentsTableColumns';
import type {DateRange} from '../StudentFilters/StudentsFilters';
import type {StudentFiltersType} from '../StudentFilters';
import type {RowData} from '../../DataTable';
import type {MemberStatus} from './types';
import {STUDENT_TYPES, type StudentType} from '../constants';
import type {UserProfilePathPrefix} from '../../Paths/user';

interface Props {
	prefix: UserProfilePathPrefix;
	filters?: StudentFiltersType[];
	columns: StudentsTableColumnsType[];
	filterStudents?: 'all' | 'verified' | 'profile_complete';
	coachId?: string;
	studentStatus?: FunnelStage | 'ARCHIVED' | 'ALL';
	domesticStudentStatus?: DomesticFunnelStage | 'ARCHIVED' | 'ALL';
	sortColumn?: StudentsTableColumnsType;
	sortType?: 'asc' | 'desc';
	initialStudentTypes?: StudentType[];
}

function getArchivedFilter(
	studentStatus?:
		| FunnelStage
		| DomesticFunnelStage
		| 'ALL'
		| 'VERIFIED'
		| 'PROFILE_COMPLETE'
		| 'ARCHIVED',
	filterStudents?: 'all' | 'verified' | 'profile_complete',
) {
	if (studentStatus === 'ARCHIVED') {
		return true;
	}

	if (studentStatus == null && filterStudents === 'all') {
		return null;
	}

	return false;
}

export function StudentsTable({
	prefix,
	filters,
	columns,
	filterStudents,
	coachId,
	studentStatus,
	domesticStudentStatus,
	sortColumn = 'insertedAt',
	sortType = 'desc',
	initialStudentTypes,
}: Props) {
	const router = useRouter();
	const userType = useUserType();
	const tableColumns = useStudentsTableColumns(columns);

	const {
		qualStatus,
		intakePeriod,
		coach,
		memberStatus,
		signUpFrom,
		signUpTo,
		country,
		studentTypes,
		domesticFunnelStage,
		latestInteractionType,
		citizenship,
	} = router.query;

	const {data: coachData} = useQuery(AdminCoachesIndexDocument);
	const coaches = coachData?.coaches;

	const defaultDomesticFunnelStage =
		domesticStudentStatus === 'ARCHIVED' || domesticStudentStatus === 'ALL'
			? null
			: domesticStudentStatus;

	const [qualStatusFilter, setQualStatusFilter] = useState<
		QualificationStatus | undefined
	>(parseQueryString(qualStatus) as QualificationStatus);

	const [intakePeriodFilter, setIntakePeriodFilter] = useState<string[]>([
		...(intakePeriod && !Array.isArray(intakePeriod) ? [intakePeriod] : []),
	]);

	const signupFrom =
		signUpFrom && !Array.isArray(signUpFrom) ? signUpFrom : null;
	const signupTo = signUpTo && !Array.isArray(signUpTo) ? signUpTo : null;

	const [signUpDateFilter, setSignUpDateFilter] = useState<
		DateRange | undefined
	>(
		signupFrom && signupTo
			? {from: new Date(signupFrom), to: new Date(signupTo)}
			: undefined,
	);

	const [memberStatusFilter, setMemberStatusFilter] = useState<
		MemberStatus | undefined
	>(parseQueryString(memberStatus) as MemberStatus);

	const [countryFilter, setCountryFilter] = useState<string | undefined>(
		parseQueryString(country),
	);

	const [citizenshipFilter, setCitizenshipFilter] = useState<
		CitizenshipTypes | undefined
	>(parseQueryString(citizenship) as CitizenshipTypes);

	const [latestInteractionTypeFilter, setLatestInteractionTypeFilter] =
		useState<LatestInteractionType | null | undefined>(
			parseQueryString(latestInteractionType) === 'null'
				? null
				: (parseQueryString(latestInteractionType) as LatestInteractionType),
		);

	const [domesticFunnelStageFilter, setDomesticFunnelStageFilter] = useState<
		DomesticFunnelStage | undefined
	>(
		defaultDomesticFunnelStage ??
			(parseQueryString(domesticFunnelStage) as DomesticFunnelStage),
	);

	useEffect(() => {
		setDomesticFunnelStageFilter(
			defaultDomesticFunnelStage ??
				(parseQueryString(domesticFunnelStage) as DomesticFunnelStage),
		);
	}, [domesticFunnelStage, defaultDomesticFunnelStage]);

	const [studentTypesFilter, setStudentTypesFilter] = useState<
		StudentType[] | undefined
	>(
		initialStudentTypes ??
			(parseQueryString(studentTypes)?.split(',') as StudentType[]) ??
			STUDENT_TYPES,
	);

	const selectedCoachId = parseQueryString(coach);
	const selectedCoach = coaches?.find((c) => c.id === selectedCoachId);

	const [coachFilter, setCoachFilter] = useState<
		| {
				id: string;
				name: string;
		  }
		| undefined
	>(
		selectedCoach
			? {id: selectedCoach.id, name: selectedCoach.user.name ?? ''}
			: undefined,
	);

	useEffect(() => {
		setCoachFilter(
			selectedCoach
				? {id: selectedCoach.id, name: selectedCoach.user.name ?? ''}
				: undefined,
		);
	}, [selectedCoach, coaches]);

	const funnelStage =
		studentStatus === 'ARCHIVED' || studentStatus === 'ALL'
			? null
			: studentStatus;

	return (
		<QueryTable
			key={`${sortColumn}-${sortType}-${filterStudents}-${studentStatus}-${domesticStudentStatus}`}
			infiniteQuery={StudentIndexDocument}
			sortColumn={sortColumn}
			sortType={sortType}
			dataKey="students.edges"
			enableSearch
			pushSearchQueryParams
			options={{
				limit: 30,
				variables: {
					filter: filterStudents,
					funnelStage: funnelStage ?? null,
					domesticFunnelStage: domesticFunnelStageFilter,
					qualificationStatus: qualStatusFilter,
					intakePeriods: intakePeriodFilter,
					signupDate: signUpDateFilter
						? [signUpDateFilter.from, signUpDateFilter.to]
						: null,
					coachId: coachFilter?.id || coachId,
					memberStatus: memberStatusFilter,
					country: countryFilter,
					citizenship: citizenshipFilter,
					studentTypes: studentTypesFilter,
					archived: getArchivedFilter(
						studentStatus ?? domesticStudentStatus,
						filterStudents,
					),
					latestInteractionType: latestInteractionTypeFilter,
				},
			}}
			onRowClick={(row: RowData) => {
				router.push({
					pathname: Paths.users.overview.index.url(
						prefix,
						row.user.id,
						friendlyURI(row.user.name),
						userType,
					),
					query: getURLQueryParameters(),
				});
			}}
			columns={tableColumns}
			rowHeight={65}
		>
			<StudentFilters
				filters={filters}
				coaches={coaches}
				domesticFunnelStage={domesticFunnelStageFilter}
				setDomesticFunnelStage={setDomesticFunnelStageFilter}
				qualificationStatus={qualStatusFilter}
				setQualStatus={setQualStatusFilter}
				intakePeriod={intakePeriodFilter}
				setIntakePeriod={setIntakePeriodFilter}
				signUpDate={signUpDateFilter}
				setSignUpDate={setSignUpDateFilter}
				coach={coachFilter}
				setCoach={setCoachFilter}
				memberStatus={memberStatusFilter}
				setMemberStatus={setMemberStatusFilter}
				country={countryFilter}
				setCountry={setCountryFilter}
				studentTypes={studentTypesFilter}
				setStudentTypes={setStudentTypesFilter}
				latestInteractionType={latestInteractionTypeFilter}
				setLatestInteractionType={setLatestInteractionTypeFilter}
				citizenship={citizenshipFilter}
				setCitizenship={setCitizenshipFilter}
			/>
		</QueryTable>
	);
}
